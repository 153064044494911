import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const BannerTwo = () => {
  return (
    <div className="banner banner2 bg-color-light">
      <div className="container">
        <div className="row align-items-end align-items-xl-start">
          <center>
            <h1 className="title">Free Splunk Training</h1>
          </center>
          <div className="col-lg-6">
            <div className="banner-thumbnail">
              <AnimationOnScroll
                animateIn="slideInRight"
                duration={1}
                delay={100}
                animateOnce={true}
              >
                <div className="large-thumb">
                  <img
                    src={process.env.PUBLIC_URL + "/images/thumbnail.jpg"}
                    alt="Course Thumbnail"
                  />
                </div>
              </AnimationOnScroll>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-content">
              <AnimationOnScroll
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}
              >
                <span className="subtitleJustified">
                  <p>
                    This course is your go-to destination for learning the
                    basics of Splunk through hands-on, instructor-led
                    demonstrations. Say goodbye to lengthy lectures and
                    PowerPoint overload—our course is meticulously designed for
                    visual learners.
                  </p>
                  <p>
                    Designed for beginners, this course takes you from noob to
                    ninja in Splunk, offering a streamlined education roadmap
                    that covers everything you need for getting started with
                    Splunk. You'll gain practical skills and boost your
                    confidence in navigate logs in search, produce
                    visualisations or create alerts.{" "}
                  </p>
                  <a
                    target="_blank"
                    href="https://www.udemy.com/course/no-nonsense-course-on-using-splunk/?referralCode=6815189CF05F673A43AD"
                    className="axil-btn btn-fill-primary btn-large"
                  >
                    View On Udemy
                  </a>
                </span>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerTwo;

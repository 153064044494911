import React from "react";
import FooterOne from "../common/footer/Footer";
import Header from "../common/header/Header";
import SEO from "../common/SEO";
import BannerOne from "../component/banner/BannerOne";
import FreeSplunkTraining from "../component/banner/FreeSplunkTraining";



const HomePage = () => {
  return (
    <>
      <SEO title="Free Splunk Training" />
      <main className="main-wrapper">
        <Header/>
        <BannerOne />
        <FreeSplunkTraining />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default HomePage;

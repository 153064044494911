import React from 'react';
import Logo from '../../elements/logo/Logo';

const HeaderOne = ( ) => {

    return (
        <>
            <header className="header axil-header header-style-1">
                <div className={`axil-mainmenu`}>
                    <div className="container">
                        <div className="header-navbar">
                            <div className="header-logo">
                            <Logo limage="/images/Splunk_Training_Logo.png"
                                dimage="/images/Splunk_Training_Logo.png"
                                simage="/images/Splunk_Training_Logo.png"
                                />      
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default HeaderOne;
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";

const Result = () => {
  return (
    <Alert variant="success" className="success-msg">
      You have been successfully added to the email list.
    </Alert>
  );
};

const EmailList = () => {
  const [email, setEmailValue] = useState("");
  const [alert, showAlert] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiUrl = "https://api.splunktraining.co.uk/addEmailToMailList";

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });

      if (response.ok) {
        showAlert(true);
      } else {
        console.error("Error submitting data");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    <>
      <h2>Get Notified!</h2>
      <p>Get notified when new courses are uploaded.</p>
      <form
        onSubmit={handleSubmit}
        className="axil-contact-form"
        style={{ display: "inline-flex" }}
      >
        <div className="form-group">
          <input
            className="form-control"
            placeholder="email"
            type="email"
            value={email}
            onChange={(e) => setEmailValue(e.target.value)}
          />
        </div>
        <div className="form-group text-end">
          <button
            type="submit"
            className="axil-btn btn-fill-primary w-auto btn-primary"
            style={{ marginLeft: "15px", padding: "18px 21px" }}
          >
            Subscribe
          </button>
        </div>
      </form>
      {alert ? <Result /> : null}
    </>
  );
};

export default EmailList;

import React from "react";
import FormThree from "../../component/contact/Form";
import SectionTitle from "../../elements/section-title/SectionTitle";
import EmailList from "../../component/EmailList";

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <SectionTitle
            id="udemy"
            title="Find Me"
            description=""
            textAlignment=""
            textColor=""
          />
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">Get in touch!</h2>
                  <FormThree />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-10">
                  <div className="footer-widget">
                    <h2 className="title">About</h2>
                    <p className="subtitleJustified">
                      Your instructor, Jacob, is a Splunk Certified Architect
                      and a true Splunk geek. I've been using Splunk for over 10
                      years, spent £10,000s on official training courses and
                      attended loads of Splunk conferences and meetups - safe to
                      say, I know what i'm talking about.
                    </p>
                    <center>
                      <img
                        width="100"
                        height="100"
                        src={process.env.PUBLIC_URL + "/images/Udemy_logo.png"}
                        alt="shape"
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@JacobAndersonCodes"
                      >
                        <img
                          width="100"
                          height="100"
                          src={
                            process.env.PUBLIC_URL + "/images/Youtube-logo.png"
                          }
                          alt="shape"
                        />
                      </a>
                    </center>
                    <br />
                    <EmailList />
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. All rights reserved by Splunk
                  Training.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  {/* <li><Link to="#">Privacy Policy</Link></li> */}
                  {/* <li><Link to="#">Terms of Use</Link></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { switchColor } from './elements/switcher/ColorSwitcher.js'


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// set to dark mode 
switchColor();

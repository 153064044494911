import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import HomePage from './pages/HomePage';


// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<HomePage />}/> 
			</Routes>
    </Router>
  )
}

export default App;

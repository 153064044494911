import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>Splunk Training | {title}</title>
            <meta name="robots" content="follow" />
            <meta name="description" content="Unleash the power of Splunk with our no-nonsense training courses. Dive straight into practical, real-world skills without the fluff – because mastering Splunk shouldn't be complicated." />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;